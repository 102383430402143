import React, { useEffect, useState, useRef } from "react";
import Logo from "../../assets/images/logo3.png";
import LinkedIn from "../../assets/images/Group9537.svg";
import FB from "../../assets/images/Group9538.svg";
import Twitter from "../../assets/images/Group17226.svg";
import YT from "../../assets/images/Vector1.svg";
import Insta from "../../assets/images/vuesax-bold-instagram.svg";
import { Link, NavLink, useLocation } from "react-router-dom";

import "./_footer.scss";
export const Footer = () => {
  const prevUrlRef = useRef(null);
  const [showNav, setShowNav] = useState(true);
  const location = useLocation();
  let currentUrl;
  useEffect(() => {
    currentUrl = location.pathname;
  });
  useEffect(() => {
    if (prevUrlRef.current !== currentUrl) {
      prevUrlRef.current = currentUrl;
      // console.log(currentUrl);
      setTimeout(() => {
        switch (currentUrl) {
          case "/login":
          case "/sign-up":
            setShowNav(false);
            break;
          default:
            setShowNav(true);
            break;
        }
        // console.log(currentUrl, showNav);
      }, 10);
    }
  });
  return (
    <>
      <div className={`footer-container    ${showNav ? "" : "hide"}`}>
        <div className="footer-content">
          <div className=" logo  ">
            <Link to={"/"}>
              <img className="" src={Logo} alt="footer logo" width="100%" />
            </Link>
          </div>
          <div className="   quick-links">
            <div className="">
              <ul className="list-unstyled mobile-ul">
                <h4>Solutions</h4>
                <li>
                  <Link to={"/educare-schools"} className="links">
                    For Schools
                  </Link>
                </li>{" "}
                <li>
                  <Link to={"/educare-business"} className="links">
                    For Businesses
                  </Link>
                </li>{" "}
              </ul>
            </div>
            <div className="">
              <ul className="list-unstyled mobile-ul">
                <h4>Company</h4>
                <li>
                  <Link to={"/about-us"} className="links">
                    About us
                  </Link>
                </li>
                <li>
                  <Link to={"/careers"} className="links">
                    Careers
                  </Link>
                </li>
                <li>
                  <Link to={"/contact-us"} className="links">
                    Contact us
                  </Link>
                </li>{" "}
              </ul>
            </div>
            <div className="">
              {" "}
              <ul className="list-unstyled mobile-ul">
                <h4>Resources</h4>
                <li>
                  <Link to={"/support"} className="links">
                    Support Center
                  </Link>
                </li>
                {/* <li>
                  <Link to={"/guides"} className="links">
                    Guides
                  </Link>
                </li> */}
                <li>
                  <Link to={"/blog"} className="links">
                    Blog
                  </Link>
                </li>
                <li>
                  <Link to={"/be-partner"} className="links">
                    Become a Partner
                  </Link>
                </li>
                <li>
                  <Link to={"/customer-stories"} className="links">
                    Customer Stories
                  </Link>
                </li>{" "}
                <li>
                  <Link to={"/contact-us"} className="links">
                    Contact Sales
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="connect ">
            <h4>Connect with us</h4>
            <ul className="list-unstyled list-inline mobile-ul social-media-icons">
          
              <li className="list-inline-item">
                {" "}
                <a
                  href="https://www.facebook.com/educareERP?mibextid=LQQJ4d"
                  target="_blank" rel="noreferrer"
                >
                  <img className="fb" src={FB} alt="facebook" width="100%" />

                </a>
              </li>
              <li className="list-inline-item">
                {" "}
                <a
                  href="https://twitter.com/educaretech?s=21&t=GEU4mJ3b4At6Rfj0mhZOmQ"
                  target="_blank" rel="noreferrer"
                >
                  {" "}
                  <img className="twi" src={Twitter} alt="twitter" width="100%" />
                </a>
               
              </li>
              {/* <br /> */}
              <li className="list-inline-item">
                {" "}
                <a
                  href="https://www.instagram.com/educare_technology/?utm_source=qr"
                  target="_blank" rel="noreferrer"
                >
                  {" "}
                  <img className="lI" src={Insta} alt="linkedin" width="100%" />
                </a>
              </li>
              <li className="list-inline-item">
                {" "}
                <a
                  href="https://www.linkedin.com/company/educare-technology/"
                  target="_blank" rel="noreferrer"
                >
                  {" "}
                    <img className="lI" src={LinkedIn} alt="linkedin" width="100%" />
                </a>
              </li>
              <li className="list-inline-item">
                {" "}
                <a
                  href="https://www.youtube.com/@educareTech"
                  target="_blank" rel="noreferrer"
                >
                  {" "}
                    <img className="lI" src={YT} alt="youtube" width="100%" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-10 offset-md-1 credits-container flexy">
          <p className="">
            © {(new Date().getFullYear())} educare Technology Solutions Limited | All Rights Reserved.
          </p>
          <ul className="list-unstyled list-inline ">
            <li className="list-inline-item">
              <Link to="/terms" className="links">
                Terms of service
              </Link>
            </li>
            <li className="list-inline-item">
              <Link to="/privacy" className="links">
                Privacy
              </Link>
            </li>
            <li className="list-inline-item">
              <Link to="/data-protection-policy" className="links">
                Data protection policy
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Footer;
